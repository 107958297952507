import React, { useState, useEffect } from 'react';
import styles from './Home.module.css';
import Hero from './Hero';

// Icons
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";

// images
import homeAboutImage from '../../Assets/Home/banner2.png';
import Antidiabetic from '../../Assets/Home/product_range/Antidiabetic.png';
import Cardiac from '../../Assets/Home/product_range/Cardiac.png';
import Gastrologic from '../../Assets/Home/product_range/Gastrologic.png';
import Gynecology from '../../Assets/Home/product_range/Gynecology.png';
import LiquidDrySyrups from '../../Assets/Home/product_range/LiquidDrySyrups.png';
import Nasaldrop from '../../Assets/Home/product_range/Nasaldrop.png';
import Orthopedic from '../../Assets/Home/product_range/Orthopedic.png';
import painkiller from '../../Assets/Home/product_range/pain_killer.png';
import Pediatric from '../../Assets/Home/product_range/Pediatric.png';
import isocertificate from '../../Assets/Home/Certficates/iso.png'
import whocertificate from '../../Assets/Home/Certficates/who.jpg'
import gmpcertificate from '../../Assets/Home/Certficates/gmp.webp'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SearchRatings from '../SearchRatings/SearchRatings';
import SEO from '../SEO/SEO';



const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const productImages = [
    { src: Antidiabetic, alt: 'Antidiabetic Medicine' },
    { src: Cardiac },
    { src: Gastrologic },
    { src: Gynecology },
    { src: LiquidDrySyrups },
    { src: Nasaldrop },
    { src: Orthopedic },
    { src: painkiller },
    { src: Pediatric }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % productImages.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + productImages.length) % productImages.length);
  };

  // Auto-slide effect
  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
    return () => clearInterval(slideInterval); // Clear interval on component unmount
  }, []);


  const images = [
    "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg", "12.jpg", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg", "19.jpg", "20.jpg", "21.jpg", "22.jpg", "23.jpg", "24.jpg", "25.jpg", "26.jpg", "27.jpg", "28.jpg", "29.jpg", "30.jpg",
  ]
  return (
    <>
      <SEO
        title="Pharmaceutical Manufacturing Excellence"
        description="BJS Healthcare Pvt Ltd is an ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer, offering over 1200 high-quality products. Trusted by 250+ clients worldwide, we specialize in tablets, capsules, soft gels, nasal sprays, and pediatric medicines."
        pagePath="/"
        pageType="Organization"
        businessInfo={{
          address: {
            streetAddress: "Office No:- 206 VTP Trade Park Katraj Hadpasar Road Undri Pune, Maharashtra.",
            addressLocality: "Pune",
            addressRegion: "Maharashtra",
            postalCode: "411060",
            addressCountry: "India"
          },
          ratingValue: 5.0,
          reviewCount: 1200
        }}
      />


      <div className={styles.home}>
        {/* Hero Section */}
        <section id='hero'>
          <Hero />
        </section>

        {/* Home showcase */}
        <section id='showcase'>
          <div className={styles.showcase_banner}>
            <div className={styles.showcase_flex}>
              <div className={styles.showcase_box}>
                <div className={styles.howcase_icon}>
                  <FaPhoneAlt />
                </div>

                <div className={styles.showcase_content}>
                  <p><a href='tel:+919730100676' >+91 9730100676</a></p>
                </div>
              </div>

              <div className={styles.showcase_box}>
                <div className={styles.howcase_icon}>
                  <MdEmail />
                </div>
                <div className={styles.showcase_content}>
                  <p><a href='mailto:info@bjshealthcare.com' >info@bjshealthcare.com</a></p>
                </div>
              </div>

              <button className={styles.connect_now}>
                <span className={styles.arrow}><FaLongArrowAltLeft /></span> Connect Now
              </button>
            </div>
          </div>
        </section>

        {/* Home About Section */}
        <section id='home_about'>
          <div className={styles.home_about}>
            <div className={styles.home_about_left}>
              <div className={styles.home_about_image}>
                <img
                  src={homeAboutImage}
                  alt="Pharmaceutical Manufacturing"
                  className={styles.image}
                />
              </div>
            </div>

            <div className={styles.home_about_right}>
              <span className={styles.subheading}>About Us</span>
              <div className={styles.home_about_heading}>
                <h2 className={styles.heading}>Welcome To BJS Healthcare</h2>
              </div>

              <p className={styles.description}>
                BJS HEALTHCARE PVT LTD. is the fastest-emerging, well-known ISO 9001-2015, WHO-GMP certified. is driven by a team of highly committed, dedicated, and experienced pharmaceutical professionals. Our commitment to excellence sets us apart in the dynamic landscape of the pharmaceutical industry.
              </p>
              <Link to='/About' className={styles.link}><button className={styles.readMore}>Read More</button></Link>
            </div>
          </div>
        </section>

        {/* Home Product Range */}
        <section id='home_product_range' className={styles.home_product_range}>
          <div className={styles.product_range_heading}>
            <h2 className={styles.heading_product_range}>Our Products Range</h2>
          </div>

          <div className={styles.home_product_range_flexbox}>
            <div className={styles.product_range_box}>
              <div className={styles.product_range_content}>
                <p className={styles.description}>
                  A renowned pharmaceutical manufacturer in India, BJS Healthcare serves over 1000+ clients
                  with a portfolio exceeding 1200 products. Our extensive range includes high-quality
                  pharmaceutical and cosmetic products range such as Painkiller, Softgel, Syrup, Nasal drop,
                  Anti diabetic, Cardiac, Gynaecology, Paediatric, Orthopedic, Gastrology. Committed to delivering effective, affordable
                  healthcare solutions, BJS Healthcare operates in compliance with WHO and GMP standards.
                  Our state-of-the-art manufacturing facilities meet all Indian government regulations for
                  quality assurance, ensuring our clients receive reliable, safe, and innovative healthcare
                  products across the globe.
                </p>
              </div>
            </div>

            <div className={styles.product_range_box}>
              <div className={styles.slider_container}>
                <button
                  className={`${styles.slider_button} ${styles.prev}`}
                  onClick={prevSlide}
                  aria-label="Previous slide"
                >
                  <IoIosArrowDropleftCircle className={styles.arrow_icon} />
                </button>

                <div className={styles.slider_content}>
                  <div className={styles.slide_wrapper}>
                    <img
                      src={productImages[currentSlide].src}
                      alt={productImages[currentSlide].title}
                      className={styles.slide_image}
                    />
                    <h3 className={styles.slide_title}>{productImages[currentSlide].title}</h3>
                  </div>
                </div>

                <button
                  className={`${styles.slider_button} ${styles.next}`}
                  onClick={nextSlide}
                  aria-label="Next slide"
                >
                  <IoIosArrowDroprightCircle className={styles.arrow_icon} />
                </button>
              </div>
            </div>
          </div>
        </section>


        {/* Home Certificates */}
        <section id='certificates'>
          <div className={styles.certificates}>
            <div className={styles.certificates_heading}>
              <h2 className={styles.heading_product_range}>Certificates</h2>
            </div>
            <div className={styles.certificate_flexbox}>
              <div className={styles.certificates_box}>
                <img src={isocertificate} alt='BJS Healthcare ISO Certified' />
              </div>
              <div className={styles.certificates_box}>
                <img src={gmpcertificate} alt='BJS Healthcare GMP Certified ' />
              </div>
              <div className={styles.certificates_box}>
                <img src={whocertificate} alt='BJS Healthcare WHO Certified' />
              </div>
            </div>

          </div>
        </section>

        {/* Home Client Section */}
        <section>
          <div className={styles.client_head}>
            <h1 className={styles.heading_product_range}>Clients</h1>
          </div>

          <div className={styles.home_client}>

            {images.map((imageName, index) => (
              <img
                key={index}
                src={`https://bjshealthcare.in/brands/${imageName}`}
                alt={`Client ${index + 1}`}
                className={styles.client_image}
              />
            ))}
          </div>
        </section>

        {/* Home contact banner */}
        <section id='home_contact_banner'>
          <div className={styles.home_contact_banner}>
            <div className={styles.home_contact_banner_content}>

              <h2>Need More Information?
                Call Us Today!</h2>
              <a href='tel:+ +91 9730100676' ><button className={styles.call_now_button}>Call Now</button></a>

            </div>
          </div>
        </section>

      </div>
    </>
  );
};

export default Home;
