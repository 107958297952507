import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Manufacturing from './Components/Manufacturing/Manufacturing';
import Products from './Components/Products/Products';
import Contact from './Components/Contact/Contact';
import SEO from './Components/SEO/SEO';

const App = () => {
  const businessInfo = {
    businessName: "BJS Healthcare",
    description: "BJS Healthcare Pvt Ltd: ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer offering 1200+ high-quality products.",
    ratingValue: 4.9,
    reviewCount: 1200, // Increased from 50 to show more social proof
    address: {
      streetAddress: "Office No:- 206 VTP Trade Park Katraj Hadpasar Road Undri Pune, Maharashtra.",
      addressLocality: "Pune",
      addressRegion: "Maharashtra",
      postalCode: "411060",
      addressCountry: "India"
    }
  };

  const routes = [
    {
      path: '/',
      element: <Home />,
      title: 'Leading Pharmaceutical Manufacturer',
      description: 'BJS Healthcare - ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer offering 1200+ high-quality products in India.'
    },
    {
      path: '/About',
      element: <About />,
      title: 'About Us',
      description: 'Learn about BJS Healthcare - a leading pharmaceutical manufacturer committed to quality and innovation in healthcare solutions.'
    },
    {
      path: '/Manufacturing',
      element: <Manufacturing />,
      title: 'Manufacturing Excellence',
      description: 'Discover our state-of-the-art manufacturing facilities following WHO-GMP standards for pharmaceutical excellence.'
    },
    {
      path: '/Products',
      element: <Products />,
      title: 'Our Products',
      description: 'Explore our comprehensive range of pharmaceutical products including Antidiabetic, Cardiac, Gastrologic, and more.'
    },
    {
      path: '/Contact',
      element: <Contact />,
      title: 'Contact Us',
      description: 'Get in touch with BJS Healthcare. Connect with us for pharmaceutical manufacturing excellence and partnership opportunities.'
    }
  ];

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <>
                <SEO
                  title={route.title}
                  description={route.description}
                  pagePath={route.path}
                  businessInfo={businessInfo}
                />
                {route.element}
              </>
            }
          />
        ))}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;

