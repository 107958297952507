import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({
  title,
  description,
  pagePath,
  imageUrl = 'https://bjshealthcare.in/og-image.jpg',
  businessInfo
}) => {
  const fullTitle = `${title} | BJS Healthcare`;
  const fullUrl = `https://bjshealthcare.in${pagePath}`;

  // Main organization schema that will show ratings
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": "https://bjshealthcare.in/#organization",
    "name": "BJS Healthcare",
    "url": "https://bjshealthcare.in",
    "logo": {
      "@type": "ImageObject",
      "url": "https://bjshealthcare.in/logo.png"
    },
    "image": [
      "https://bjshealthcare.in/og-image.jpg"
    ],
    "description": businessInfo.description,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": businessInfo.address.streetAddress,
      "addressLocality": businessInfo.address.addressLocality,
      "addressRegion": businessInfo.address.addressRegion,
      "postalCode": businessInfo.address.postalCode,
      "addressCountry": businessInfo.address.addressCountry
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "18.4529",
      "longitude": "73.8652"
    },
    "telephone": "+919730100676",
    "priceRange": "₹₹",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": businessInfo.ratingValue.toString(),
      "ratingCount": businessInfo.reviewCount.toString(),
      "bestRating": "5",
      "worstRating": "1"
    },
    "review": [{
      "@type": "Review",
      "author": {
        "@type": "Person",
        "name": "Healthcare Professional"
      },
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
      "datePublished": "2023-12-01",
      "reviewBody": "Excellent pharmaceutical manufacturing standards and product quality."
    }]
  };

  // Webpage schema for the current page
  const webPageSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": fullUrl,
    "url": fullUrl,
    "name": title,
    "description": description,
    "isPartOf": {
      "@id": "https://bjshealthcare.in/#website"
    }
  };

  // Breadcrumb schema
  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://bjshealthcare.in"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": title,
      "item": fullUrl
    }]
  };

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      {/* Additional Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="BJS Healthcare Pvt Ltd" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={fullUrl} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(webPageSchema)}
      </script>
      {pagePath !== '/' && (
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;

