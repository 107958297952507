import React, { useState, useEffect } from 'react';
import styles from './Products.module.css';
import Productdata from './Data';

import about_hero from '../../Assets/About/about_hero.mp4';
import { Helmet } from 'react-helmet';
import SearchRatings from '../SearchRatings/SearchRatings';
import SEO from '../SEO/SEO';

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default to "All"
  const [filteredProducts, setFilteredProducts] = useState(Productdata); // Display all products initially
  const [searchTerm, setSearchTerm] = useState(''); // No search term initially

  const categories = ['All', 'Betalactam', 'Nonbetalactam', 'Softgel Capsules', 'Liquids/Syrup', 'Injectable'];

  useEffect(() => {
    // Filter whenever the category or search term changes
    const filterProducts = () => {
      let filtered = Productdata;

      // 1. Filter by category (if not "All")
      if (selectedCategory !== 'All') {
        filtered = filtered.filter(
          (product) => product.category.toLowerCase() === selectedCategory.toLowerCase()
        );
      }

      // 2. Filter by search term (if entered)
      if (searchTerm.trim() !== '') {
        filtered = filtered.filter((product) =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // Update the state with the filtered products
      setFilteredProducts(filtered);
    };

    filterProducts();
  }, [selectedCategory, searchTerm]); // Dependencies: Re-run whenever these change

  return (
    <>
   <SEO 
        title="Products"
        description="Explore the wide range of pharmaceutical products offered by BJS Healthcare. Our portfolio includes Betalactam, Nonbetalactam, Softgel Capsules, Liquids, Syrups, and Injectable formulations, ensuring quality and innovation in every solution."
        pagePath="/Products"
        pageType="CollectionPage"
        businessInfo={{
          address: {
            streetAddress: "Office No:- 206 VTP Trade Park Katraj Hadpasar Road Undri Pune, Maharashtra.",
            addressLocality: "Pune",
            addressRegion: "Maharashtra",
            postalCode: "411060",
            addressCountry: "India"
          },
          ratingValue: 5.0,
          reviewCount: 1200
        }}
      />
    <div className={styles.productsContainer}>
      {/* Hero Section */}
      <div className={styles.heroSection}>
        <video autoPlay loop muted className={styles.heroVideo}>
          <source src='https://yeskondomedia.com/Videos/about_hero.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.heroOverlay}></div> {/* Overlay added */}
        <div className={styles.heroContent}>
          <h1>Our Products</h1>
          <p>Discover our wide range of pharmaceutical solutions</p>
        </div>
      </div>

      {/* Product Section */}
      <div className={styles.productSection}>
        {/* Category Filter */}
        <div className={styles.categoryFilter}>
          {categories.map((category, index) => (
            <button
              key={index}
              className={`${styles.categoryButton} ${selectedCategory === category ? styles.active : ''
                }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Search Bar */}
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Product Grid */}
        <div className={styles.productGrid}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div key={product.id} className={styles.productCard}>
                <h4>{product.name}</h4>
                <p>Drug Class: {product.category}</p>
                <p>Dosage Form: {product.subcategory}</p>
              </div>
            ))
          ) : (
            <p className={styles.noProducts}>No products found</p>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default Products;
